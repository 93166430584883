import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_GlobalPopup = _resolveComponent("GlobalPopup")!
  const _component_GlobalLoader = _resolveComponent("GlobalLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_Footer),
    _createVNode(_component_GlobalPopup, {
      show: _ctx.popupVisible,
      message: _ctx.popupMessage,
      "is-error": _ctx.popupIsError,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hidePopup()))
    }, null, 8, ["show", "message", "is-error"]),
    _createVNode(_component_GlobalLoader, {
      show: _ctx.loaderVisible,
      message: _ctx.loaderMessage
    }, null, 8, ["show", "message"])
  ], 64))
}