import { createI18n } from "vue-i18n";
import en from "./translations/en";
import cs from "./translations/cs";
import de from "./translations/de";

// function pluralizationChoiceIndex(
//   choice: number,
//   choicesLength: number
// ): number {
//   if (choice === 1) return 0;
//   if (choice >= 2 && choice <= 4) return 1;
//   return 2;
// }

export const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en,
    cs,
    de,
  },
  // pluralizationRules: { cs: pluralizationChoiceIndex },
});
