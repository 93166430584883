import axios from "axios";

import { API_URL } from "./config";

const instance = axios.create({
  baseURL: API_URL,
  timeout: 3000,
  withCredentials: true,
  //   headers: { "X-Custom-Header": "foobar" },
});

export default instance;
