<template>
  <div class="homepage">
    <div class="container">
      <div class="row description">
        <div class="col-md-5">
          <p class="first">
            <span class="big-text">{{ $t("home.desc0") }}</span
            >{{ $t("home.desc1") }}
          </p>
          <p>{{ $t("home.desc2") }}</p>
          <p>{{ $t("home.desc3") }}</p>
          <p>{{ $t("home.desc4") }}</p>

          <div class="centered">
            <router-link to="/gallery">
              <button class="explore-btn">
                {{ $t("home.exploreBtn") }}
              </button>
            </router-link>
          </div>
        </div>
        <div class="col-md-4 offset-md-1">
          <img
            class="img-fluid"
            src="./../assets/precismo_scan.png"
            alt="Confirmo scan"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {},
})
export default class HomePage extends Vue {}
</script>
