export default {
  nav: {
    home: "Home",
    about: "About",
    mint: "Mint",
    login: "Login",
    register: "Register",
    account: "Account",
    gallery: "Gallery",
    certifications: "Certifications",
    market: "Market",
    investor: "Investor",
    artist: "Artist",
    depositary: "Depositary",
    creator: "Creator",
    news: "Newsletter",
    contact: "Contact",
    gdpr: "Customer data",
    tos: "Terms and Conditions",
    search: "Asset, artist...",
    createArt: "Create Art",
  },
  about: {
    title:
      "Virtual Gallery is a unique project focusing on connecting real-world values with digital assets.",
    team: "Our team:",
    partners: "Partners:",
  },
  account: {
    menu: {
      summary: "Summary",
      virtualGallery: "Virtual Gallery",
      wallet: "Wallet",
      recommendations: "Recommendations",
      profile: "My profile",
      settings: "Settings",
      security: "Security",
      logOut: "Log out",
      successfulLogOut: "You have successfully logged out.",
      notLoggedIn: "You are not logged in.",
    },
    summary: {
      userId: "User ID",
      accountType: "Account type",
      lastLogin: "Last login",
      portfolioBuyingPrice: "Portfolio buying price",
      sold: "Sold",
      averageProfit: "Average profit",
      assetsInDeposit: "Assets in deposit",
      assetsInSelfCustody: "Assets in self custody",
      assetsInGallery: "Assets in Gallery",
      profitFromRoyalties: "Profit from Royalties",
      notFullyRegistered:
        "You are not fully registered. Please complete your registration. Click here to continue.",
    },
    virtualGallery: {
      galleryURL: "Your gallery URL",
      followers: "Followers",
      following: "Following",
      yourGallery: "Your gallery",
    },
    wallet: {
      btc: "BTC",
      fiat: "Fiat",
      transactionAmount: "Transaction amount",
      depositWithdraw: "Deposit / Withdraw",
      yourPortfolio: "Your portfolio",
      wallet: "Wallet",
      marketplace: "Marketplace",
      transactionHistory: "Transaction history",
      btcCertificates: "BTC certificates",
    },
    recommendations: {
      recommendationsURL: "Your affiliate URL",
      provisionSummary: "Provision summary",
      notPaid: "Not paid",
      paymentRequests: "Payment requests",
      alreadyPaid: "Already paid",
      getPayment: "Get payment",
      client: "Client",
      level: "Level",
      date: "Date",
      lastSale: "Last sale",
      portfolioSize: "Portfolio size",
      provisionsToPay: "Provisions to pay",
      provisionSizes: "Provision sizes",
      codeChanged: "Affiliate code changed",
      codeEmpty: "Code cannot be empty",
    },
    profile: {
      userId: "User ID",
      accountType: "Account type",
      lastLogin: "Last login",
      name: "Name",
      surname: "Surname",
      nickName: "Nick Name",
      web: "Web",
      phone: "Mobil",
      email: "email",
      instagram: "Instagram",
      twitter: "Twitter",
      youtube: "YouTube",
      telegram: "Telegram",
      discord: "Discord",
      tiktok: "TikTok",
      changeAvatar: "Change avatar",
      removeAvatar: "Remove avatar",
      dataUpdated: "Data updated successfully.",
      description: "Description",
      fullRegistration: "Full registration",
      subjectType: "Subject type",
      type: {
        individual: "Individual",
        entrepreneur: "Entrepreneur",
        company: "Company",
      },
      bankNumber: "Bank number",
      homeAddress: "Home address",
      contractDetails: "Contract details",
      streetAndNumber: "Street and number",
      city: "City",
      postalNumber: "Postal Number",
      ico: "ICO Number",
      dic: "DIC Number",
      companyName: "Company Name",
      detailsUpdated: "Full registration details updated successfully.",
      avatarChangedSuccessfully: "Avatar changed successfully.",
    },
    security: {
      accountSecurity: "Account security",
      twoFA: "2FA",
      verifiedIdentity: "Verified identity",
      antiPhishingCode: "Anti-phishing code",
      verifiedBankAccount: "Verified bank account",
      titleTwoFA: "Two-factor authentication (2FA)",
      biometrics: "Biometrics",
      googleAuthenticator: "Google Authenticator",
      phoneVerification: "Phone number verification",
      emailVerification: "Email address verification",
      advancedSecurity: "Advanced security",
      password: "Password",
      accountConnection: "Account connection",
      fundWithdrawal: "Fund withdrawal",
    },
  },
  buttons: {
    show: "Show",
    change: "Change",
    manage: "Manage",
    edit: "Edit",
    save: "Save",
    qrcode: "QR Code",
    send: "Send",
    login: "Login",
    submit: "Submit",
    register: "Register",
    logOut: "Log out",
    statistics: "Statistics",
    termsAndConditions: "Terms and Conditions",
    cancel: "Cancel",
  },
  sortBy: {
    sortBy: "Sort by",
    price: "Price",
    date: "Date",
    type: "Type",
    artist: "Artist",
  },
  assetType: {
    wines: "Wines",
    art: "Art",
    veterans: "Veterans",
  },
  home: {
    header: "Virtual Gallery - where physical meets digital",
    welcome: "Welcome to the future of art.",
    exploreBtn: "EXPLORE",
    desc0: "We are launching",
    desc1: "a new era of digital & real values.",
    desc2:
      "The authenticity of each piece is recorded on a decentralized Bitcoin blockchain.",
    desc3: "Your valuables are protected in a perfectly secured repository.",
    desc4: "Create your virtual gallery right now!",
  },
  login: {
    header: "Log in to the application",
    mailOrPhone: "Email",
    password: "Password",
    createNewAccount: "Create new account",
    loginSuccessMessage: "You have successfully logged in.",
    alreadyLoggedIn: "You are already logged in.",
    forgotPassword: "Forgotten password?",
    loginViaGoogle: "Login via Google",
    loginViaApple: "Login via Apple",
  },
  register: {
    header: "Register to the application",
    finishHeader: "Finish registration",
    name: "Name",
    surname: "Surname",
    email: "Email",
    phone: "Phone",
    accountType: "Account Type",
    emailCode: "Input verification code from email",
    phoneCode: "Input verification code from phone",
    password: "Input your password",
    passwordConfirmation: "Confirm your password",
    alreadyHaveAccount: "Already have an account?",
    registerFirstSuccess:
      "Registration in progress. Please check your email and phone for verification codes.",
    registerSecondSuccess: "Registration completed. You can now log in.",
    loader: "Registering...",
  },
  registerFinish: {
    header: "Please finish the basic registration.",
    success: "Basic registration successfully finished!",
  },
  mint: {
    header: "Mint an ordinal",
    textContent: "Text content",
    btcAddress: "BTC taproot address (testnet)",
  },
  accountType: {
    investor: "Investor",
    artist: "Artist",
    depository: "Depository",
    creator: "Creator",
  },
  words: {
    item: "item | items",
  },
  footer: {
    descriptionFirst:
      "The world's first and largest digital marketplace for Bitcoin certificates backed by real value.",
    descriptionSecond:
      "Buy, sell, offer your licenses and discover exclusive digitized real investment items.",
    join: "Join the community",
  },
  notFound: {
    header: "404 - Page not found",
    text: "The page you are looking for doesn't exist.",
  },
  asset: {
    type: "Type",
    owner: "Owner",
    depositary: "Depositary",
    artist: "Artist",
    created: "Created",
  },
  passwordChange: {
    title: "Enter your password and create a new one",
    old: "Input your old password",
    new: "Input your new password",
    confirm: "Confirm your new password",
    changePassword: "Change password",
    changedSuccessfully: "Password changed successfully!",
  },
  forgottenPassword: {
    title: "Enter the email of your account",
    send: "Send email",
    email: "Your email",
    checkYourEmail: "Check your email for a reset link",
  },
  passwordReset: {
    title: "Reset your password",
    password: "Your new password",
    passwordConfirmation: "Confirm password",
    button: "Change password",
    success: "Your password has been successfully changed.",
    notMatch: "Passwords do not match.",
    loader: "Resetting the password...",
  },
  loader: {
    gettingData: "Getting data...",
    loading: "Loading...",
    verifying: "Verifying...",
    savingData: "Saving data...",
    sendingEmail: "Sending email...",
    loggingOut: "Logging out...",
    uploading: "Uploading...",
    searching: "Searching...",
  },
  createAsset: {
    assetCreation: "Asset creation",
    createAsset: "Create asset",
    certificateNumber: "Certificate number (optional)",
    assetName: "Asset name",
    creationYear: "Creation year",
    description: "Asset description (600 characters)",
    creationYearNotValid: "Invalid creation year",
    height: "Height (cm)",
    width: "Width (cm)",
    technique: "Technique",
    style: "Style",
    success: "Art created successfully",
    uploadPicture: "Upload picture",
    pleaseSelectFile: "Please select file",
    noPermissionToCreateArt: "You do not have permission to create art.",
    techniqueOptions: {
      one: "Akryl",
      two: "Balakryl",
      three: "Calakryl",
    },
    styleOptions: {
      one: "MyStyle",
      two: "PyStyle",
      three: "YourStyle",
    },
  },
  search: {
    title: "Search results",
    artistsTitle: "Artists",
    assetsTitle: "Assets",
    noResults: "No results found.",
  },
  passwordsDoNotMatch: "Passwords do not match.",
  pleaseTryAgain: "Please try again.",
  pleaseEnterAValidValue: "Please enter a valid value.",
  turnOn: "Turn on",
  turnOff: "Turn off",
};
