// LOCAL
export const API_URL = "http://localhost:3000";
// export const REQUIRE_AUTH = false;

// DEV
// export const API_URL = "https://virtualgallery.grdddj.eu/api";
export const REQUIRE_AUTH = true;

// TODO: choose these automatically according to the build command
// npm run build:dev etc.
