export default {
  nav: {
    home: "Start",
    about: "Über uns",
    mint: "Münzen",
    login: "Anmelden",
    register: "Registrieren",
    account: "Konto",
    gallery: "Galerie",
    certifications: "Zertifikate",
    market: "Markt",
    investor: "Investor",
    artist: "Künstler",
    depositary: "Depositar",
    creator: "Schöpfer",
    news: "Newsletter",
    contact: "Kontakt",
    gdpr: "Kundendaten",
    tos: "Geschäftsbedingungen",
    search: "Asset, Künstler...",
    createArt: "Kunst erstellen",
  },
  about: {
    title:
      "Virtual Gallery ist ein einzigartiges Projekt, das sich auf die Verbindung von realen Werten mit digitalen Vermögenswerten konzentriert.",
    mission: "Unsere Mission ist...",
    team: "Unseren Team:",
    partners: "Partners:",
  },
  account: {
    menu: {
      summary: "Zusammenfassung",
      virtualGallery: "Virtuelle Galerie",
      wallet: "Brieftasche",
      recommendations: "Empfehlungen",
      profile: "Mein Profil",
      settings: "Einstellungen",
      security: "Sicherheit",
      logOut: "Abmelden",
      successfulLogOut: "Sie haben sich erfolgreich abgemeldet.",
      notLoggedIn: "Sie sind nicht angemeldet.",
    },
    summary: {
      userId: "Benutzer-ID",
      accountType: "Kontotyp",
      lastLogin: "Letzte Anmeldung",
      portfolioBuyingPrice: "Kaufpreis des Portfolios",
      sold: "Verkauft",
      averageProfit: "Durchschnittlicher Gewinn",
      assetsInDeposit: "Vermögenswerte im Depot",
      assetsInSelfCustody: "Vermögenswerte in Eigenverwahrung",
      assetsInGallery: "Vermögenswerte in der Galerie",
      profitFromRoyalties: "Gewinn aus Lizenzgebühren",
      notFullyRegistered:
        "Sie sind nicht vollständig registriert. Bitte schließen Sie Ihre Registrierung ab. Klicken Sie hier, um fortzufahren.",
    },
    virtualGallery: {
      galleryURL: "Ihre Galerie-URL",
      followers: "Follower",
      following: "Folgt",
      yourGallery: "Ihre Galerie",
    },
    wallet: {
      btc: "BTC",
      fiat: "Fiat",
      transactionAmount: "Transaktionsbetrag",
      depositWithdraw: "Einzahlen / Abheben",
      yourPortfolio: "Ihr Portfolio",
      wallet: "Brieftasche",
      marketplace: "Marktplatz",
      transactionHistory: "Transaktionsverlauf",
      btcCertificates: "BTC-Zertifikate",
    },
    recommendations: {
      recommendationsURL: "Ihre Empfehlungs-URL",
      provisionSummary: "Provisionsübersicht",
      notPaid: "Nicht bezahlt",
      paymentRequests: "Zahlungsanforderungen",
      alreadyPaid: "Bereits bezahlt",
      getPayment: "Zahlung erhalten",
      client: "Klient",
      level: "Stufe",
      date: "Datum",
      lastSale: "Letzter Verkauf",
      portfolioSize: "Portfolio-Größe",
      provisionsToPay: "Zu zahlende Provisionen",
      provisionSizes: "Provisionsgrößen",
      codeChanged: "Ihr Empfehlungscode wurde erfolgreich geändert.",
      codeEmpty: "Ihr Empfehlungscode darf nicht leer sein.",
    },
    profile: {
      userId: "Benutzer-ID",
      accountType: "Kontotyp",
      lastLogin: "Letzte Anmeldung",
      name: "Name",
      surname: "Nachname",
      nickName: "Spitzname",
      web: "Web",
      phone: "Mobil",
      email: "E-Mail",
      instagram: "Instagram",
      twitter: "Twitter",
      youtube: "YouTube",
      telegram: "Telegram",
      discord: "Discord",
      tiktok: "TikTok",
      changeAvatar: "Avatar ändern",
      removeAvatar: "Avatar entfernen",
      dataUpdated: "Ihre Daten wurden erfolgreich aktualisiert.",
      description: "Beschreibung",
      fullRegistration: "Vollständige Registrierung",
      subjectType: "Subjekttyp",
      type: {
        individual: "Individuum",
        entrepreneur: "Unternehmer",
        company: "Unternehmen",
      },
      bankNumber: "Banknummer",
      homeAddress: "Heimatadresse",
      contractDetails: "Vertragsdetails",
      streetAndNumber: "Straße und Nummer",
      city: "Stadt",
      postalNumber: "Postleitzahl",
      ico: "ICO-Nummer",
      dic: "DIC-Nummer",
      companyName: "Firmenname",
      detailsUpdated: "Ihre Details wurden erfolgreich aktualisiert.",
      avatarChangedSuccessfully: "Avatar wurde erfolgreich geändert.",
    },
    security: {
      accountSecurity: "Kontosicherheit",
      twoFA: "2FA",
      verifiedIdentity: "Verifizierte Identität",
      antiPhishingCode: "Anti-Phishing-Code",
      verifiedBankAccount: "Verifiziertes Bankkonto",
      titleTwoFA: "Zwei-Faktor-Authentifizierung (2FA)",
      biometrics: "Biometrie",
      googleAuthenticator: "Google Authenticator",
      phoneVerification: "Telefonnummer-Verifizierung",
      emailVerification: "E-Mail-Adresse-Verifizierung",
      advancedSecurity: "Erweiterte Sicherheit",
      password: "Passwort",
      accountConnection: "Kontoverbindung",
      fundWithdrawal: "Fondsauszahlung",
    },
  },
  buttons: {
    show: "Anzeigen",
    change: "Ändern",
    manage: "Verwalten",
    edit: "Bearbeiten",
    save: "Speichern",
    login: "Anmelden",
    register: "Registrieren",
    logOut: "Abmelden",
    qrcode: "QR-Code",
    send: "Senden",
    statistics: "Statistiken",
    termsAndConditions: "Geschäftsbedingungen",
    cancel: "Abbrechen",
  },
  sortBy: {
    sortBy: "Sortieren nach",
    price: "Preis",
    date: "Datum",
    type: "Typ",
    artist: "Künstler",
  },
  assetType: {
    wines: "Weine",
    art: "Kunst",
    veterans: "Veteranen",
  },
  home: {
    header: "Virtuelle Galerie - wo Physisches auf Digitales trifft",
    welcome: "Willkommen in der Zukunft der Kunst.",
    exploreBtn: "EXPLORE",
    desc0: "Wir starten",
    desc1: "eine neue Ära digitaler & realer Werte.",
    desc2:
      "Die Authentizität jedes Werks wird auf einer dezentralisierten Bitcoin-Blockchain festgehalten.",
    desc3: "Ihre Wertsachen sind in einem perfekt gesicherten Depot geschützt.",
    desc4: "Erstellen Sie jetzt Ihre virtuelle Galerie!",
  },
  login: {
    header: "In die Anwendung einloggen",
    mailOrPhone: "E-Mail",
    password: "Passwort",
    createNewAccount: "Neues Konto erstellen",
    loginSuccessMessage: "Sie haben sich erfolgreich angemeldet.",
    alreadyLoggedIn: "Sie sind bereits angemeldet.",
    forgotPassword: "Passwort vergessen?",
    loginViaGoogle: "Login über Google",
    loginViaApple: "Login über Apple",
  },
  register: {
    header: "In der Anwendung registrieren",
    finishHeader: "Registrierung abschließen",
    name: "Name",
    surname: "Nachname",
    email: "E-Mail",
    phone: "Telefon",
    accountType: "Kontotyp",
    emailCode: "Geben Sie den Bestätigungscode aus der E-Mail ein",
    phoneCode: "Geben Sie den Bestätigungscode aus dem Telefon ein",
    password: "Geben Sie Ihr Passwort ein",
    passwordConfirmation: "Bestätigen Sie Ihr Passwort",
    alreadyHaveAccount: "Haben Sie bereits ein Konto?",
    registerFirstSuccess:
      "Sie haben sich erfolgreich registriert. Bitte überprüfen Sie Ihre E-Mails und Handys.",
    registerSecondSuccess:
      "Sie haben sich erfolgreich registriert. Bitte loggen Sie sich ein.",
    loader: "Registrierung läuft...",
  },
  registerFinish: {
    header: "Registrierung abschließen",
    success: "Sie haben sich erfolgreich registriert.",
  },
  mint: {
    header: "Einen Ordnungszahl prägen",
    textContent: "Textinhalt",
    btcAddress: "BTC Taproot-Adresse (Testnetz)",
  },
  accountType: {
    investor: "Investor",
    artist: "Künstler",
    depository: "Depot",
    creator: "Schöpfer",
  },
  words: {
    item: "Artikel",
  },
  footer: {
    descriptionFirst:
      "Der weltweit erste und größte digitale Marktplatz für Bitcoin-Zertifikate, die durch echten Wert abgesichert sind.",
    descriptionSecond:
      "Kaufen, verkaufen, Ihre Lizenzen anbieten und exklusive digitalisierte echte Investmentobjekte entdecken.",
    join: "Treten Sie der Gemeinschaft bei",
  },
  notFound: {
    header: "404 - Seite nicht gefunden",
    text: "Die Seite, die Sie suchen, existiert nicht.",
  },
  asset: {
    type: "Typ",
    owner: "Besitzer",
    depositary: "Depot",
    artist: "Künstler",
    created: "Erstellt",
  },
  passwordChange: {
    title: "Eine alte und neue Passwort",
    old: "Alte Passwort",
    new: "Neue Passwort",
    confirm: "Neue Passwort noch einmal",
    changePassword: "Passwort ändern",
    changedSuccessfully: "Passwort wurde erfolgreich geändert.",
  },
  forgottenPassword: {
    title: "Geben Sie die E-Mail Ihres Kontos ein",
    send: "E-Mail senden",
    email: "Ihre E-Mail",
  },
  passwordReset: {
    title: "Setzen Sie Ihr Passwort zurück",
    password: "Ihr neues Passwort",
    passwordConfirmation: "Passwort bestätigen",
    button: "Passwort ändern",
    success: "Ihr Passwort wurde erfolgreich geändert.",
    notMatch: "Passwörter stimmen nicht überein.",
    loader: "Passwort ändern...",
  },
  loader: {
    gettingData: "Daten werden abgerufen...",
    loading: "Laden...",
    verifying: "Verifizieren...",
    savingData: "Daten werden gespeichert...",
    sendingEmail: "Email senden...",
    loggingOut: "Abmelden...",
    uploading: "Laden...",
    searching: "Suchen...",
  },
  createAsset: {
    assetCreation: "Asset-Erstellung",
    createAsset: "Asset erstellen",
    certificateNumber: "Zertifikatsnummer (optional)",
    assetName: "Asset-Name",
    creationYear: "Erstellungsjahr",
    description: "Asset-Beschreibung (600 Zeichen)",
    creationYearNotValid: "Ungültiges Erstellungsjahr",
    height: "Höhe (cm)",
    width: "Breite (cm)",
    technique: "Technik",
    style: "Stil",
    success: "Kunst erfolgreich erstellt",
    uploadPicture: "Picture Laden",
    pleaseSelectFile: "Bitte Laden Picture",
    noPermissionToCreateArt: "Sie haben kein Rechts erstellungen.",
    techniqueOptions: {
      one: "Akryl",
      two: "Balakryl",
      three: "Calakryl",
    },
    styleOptions: {
      one: "MeinStil",
      two: "PyStil",
      three: "IhrStil",
    },
  },
  search: {
    title: "Suche Ergebnisse",
    artistsTitle: "Künstler",
    assetsTitle: "Assets",
    noResults: "Keine Ergebnisse",
  },
  passwordsDoNotMatch: "Passwörter stimmen nicht überein.",
  pleaseTryAgain: "Bitte versuchen Sie es erneut.",
  pleaseEnterAValidValue: "Bitte geben Sie einen gültigen Wert ein.",
  turnOn: "Einschalten",
  turnOff: "Ausschalten",
};
