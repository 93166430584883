<template>
  <div class="header">
    <div class="logo">
      <router-link to="/">
        <img src="./../assets/logo_zelvuska_cut.png" alt="Logo" height="70px" />
      </router-link>
    </div>
    <div class="nav-links">
      <router-link to="/notifications">
        <button>Notifications</button>
      </router-link>
      <router-link to="/create_notifications">
        <button>Create Notifications</button>
      </router-link>
    </div>
    <div v-if="isAuthenticated()" class="user">
      <div class="row">
        <div class="col">
          <div class="d-flex">
            <div class="icon-number-container" @click="showUserMessages()">
              <img
                src="./../assets/dialog.svg"
                alt="dialog"
                class="icon"
                height="40px"
              />
              <span class="number-in-icon">{{ userInfo.messages }}</span>
            </div>
            <div class="icon-number-container" @click="showUserNotifications()">
              <img
                src="./../assets/bell.svg"
                alt="dialog"
                class="icon"
                height="40px"
              />
              <span class="number-in-icon">{{ userInfo.notifications }}</span>
            </div>
          </div>
          <p class="user-name">
            {{ userInfo.nickName }}
          </p>
        </div>
        <div class="col d-flex align-items-end">
          <div class="avatar-and-arrow" @click="toggleUserMenu()">
            <img :src="userAvatar()" alt="Avatar" height="60px" />
            <i v-if="!shouldShowUserMenu" class="fa fa-angle-down" />
            <i v-if="shouldShowUserMenu" class="fa fa-angle-up" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isAuthenticated()" class="login-register">
      <div>
        <router-link to="/login">
          <button>
            {{ $t("nav.login") }}
          </button>
        </router-link>
      </div>
    </div>
    <div v-if="!isAuthenticated()" class="language">
      <div>
        <img
          :class="{ 'active-lang': getLanguage() === 'cs' }"
          src="./../assets/flag_cs.png"
          alt="Czech"
          @click="changeLanguage('cs')"
        />
      </div>
      <div>
        <img
          :class="{ 'active-lang': getLanguage() === 'en' }"
          src="./../assets/flag_en.png"
          alt="English"
          @click="changeLanguage('en')"
        />
      </div>
      <div>
        <img
          :class="{ 'active-lang': getLanguage() === 'de' }"
          src="./../assets/flag_de.png"
          alt="Deutsch"
          @click="changeLanguage('de')"
        />
      </div>
    </div>
  </div>

  <UserPopupMenu
    :user-info="userInfo"
    :show="shouldShowUserMenu"
    @close="closeUserMenu"
  />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import UserPopupMenu from "./UserPopupMenu.vue";
import axios from "@/axios";
import {
  logFrontendAction,
  loaderPopupAxiosErrorWrapper,
  imageAvatarUrl,
} from "@/injection-keys";

@Options({
  props: {},
  inject: {
    loaderPopupAxiosErrorWrapper: loaderPopupAxiosErrorWrapper,
    logFrontendAction: logFrontendAction,
    imageAvatarUrl: imageAvatarUrl,
  },
  components: {
    UserPopupMenu,
  },
  async created() {
    await this.fetchData();
  },
})
export default class Header extends Vue {
  avatarReloadCounter = 0;
  searchText = "";
  userInfo = {
    accountType: "",
    nickName: "",
    email: "",
    id: "",
    avatar: "",
    notifications: 0,
    messages: 0,
  };

  shouldShowUserMenu = false;
  shouldShowUserMessages = false;
  shouldShowUserNotifications = false;

  logFrontendAction!: (type: string, message: string) => void;
  imageAvatarUrl!: (avatarName: string) => string;
  loaderPopupAxiosErrorWrapper!: (
    loaderMessage: string,
    callback: () => Promise<void>
  ) => Promise<void>;

  async fetchData() {
    await this.loaderPopupAxiosErrorWrapper(
      this.$t("loader.gettingData"),
      async () => {
        const res = await axios.get("/account/menu");
        this.userInfo = res.data.data;
      }
    );
  }

  isAuthenticated(): boolean {
    return this.$store.state.isAuthenticated;
  }

  userAvatar(): string {
    return this.imageAvatarUrl(this.userInfo.id);
  }

  async changeLanguage(lang: string) {
    this.$i18n.locale = lang;
    await this.logFrontendAction(`change_language`, `Changed to: ${lang}`);
  }

  getLanguage(): string {
    return this.$i18n.locale;
  }

  getPlaceholder(): string {
    return this.$t("nav.search");
  }

  toggleUserMenu() {
    this.shouldShowUserMenu = !this.shouldShowUserMenu;
  }

  closeUserMenu() {
    this.shouldShowUserMenu = false;
  }

  showUserMessages() {
    this.shouldShowUserMessages = true;
  }

  showUserNotifications() {
    this.shouldShowUserNotifications = true;
  }
}
</script>
