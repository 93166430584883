import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import { i18n } from "./i18n";
import "./styles/style.css";

import { API_URL } from "./config";

const app = createApp(App);
app.config.globalProperties.$API_URL = API_URL;
app.use(router).use(store).use(i18n).mount("#app");
