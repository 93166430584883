<template>
  <Header />
  <div class="content">
    <router-view />
  </div>
  <Footer />

  <!-- Sometimes visible components -->
  <GlobalPopup
    :show="popupVisible"
    :message="popupMessage"
    :is-error="popupIsError"
    @close="hidePopup()"
  />
  <GlobalLoader :show="loaderVisible" :message="loaderMessage" />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import GlobalPopup from "@/components/GlobalPopup.vue";
import GlobalLoader from "@/components/GlobalLoader.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import axios from "@/axios";
import router from "@/router";
import {
  showPopup,
  showLoader,
  hideLoader,
  logOut,
  logFrontendAction,
  imageAssetUrl,
  imageAvatarUrl,
  loaderPopupAxiosErrorWrapper,
} from "@/injection-keys";

@Options({
  components: {
    GlobalPopup,
    GlobalLoader,
    Header,
    Footer,
  },
  provide() {
    return {
      [showPopup]: this.showPopup,
      [showLoader]: this.showLoader,
      [hideLoader]: this.hideLoader,
      [logOut]: this.logOut,
      [logFrontendAction]: this.logFrontendAction,
      [imageAssetUrl]: this.imageAssetUrl,
      [imageAvatarUrl]: this.imageAvatarUrl,
      [loaderPopupAxiosErrorWrapper]: this.loaderPopupAxiosErrorWrapper,
    };
  },
  async created() {
    await this.authenticate();
  },
})
export default class App extends Vue {
  popupVisible = false;
  popupMessage = "";
  popupIsError = false;
  loaderVisible = false;
  loaderMessage = "";

  async authenticate() {
    try {
      const response = await axios.post("/internal/auth", {});
      if (response.data.success && response.data.data) {
        this.$store.dispatch("logUserIn", {
          email: response.data.data.email,
          accountType: response.data.data.accountType,
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err);
      this.showPopup("Authentication failed", true);
      await this.logFrontendAction(
        `http_error`,
        `Error in authenticate(): ${err}`
      );
    }
  }

  showPopup(message: string, isError = false) {
    this.popupMessage = message;
    this.popupIsError = isError;
    this.popupVisible = true;
  }

  hidePopup() {
    this.popupVisible = false;
  }

  showLoader(message: string) {
    this.loaderMessage = message;
    this.loaderVisible = true;
  }

  hideLoader() {
    this.loaderVisible = false;
  }

  async logOut() {
    await this.loaderPopupAxiosErrorWrapper(
      this.$t("loader.loggingOut"),
      async () => {
        await axios.post("/logout", {});
        this.$store.dispatch("logUserOut");
        this.showPopup(this.$t("account.menu.successfulLogOut"));
        router.push("/");
      }
    );
  }

  async logFrontendAction(type: string, message: string) {
    await axios.post("/log", { type, message });
  }

  imageAssetUrl(assetId: string): string {
    return `${axios.defaults.baseURL}/image/asset/${assetId}`;
  }

  imageAvatarUrl(userId: string): string {
    return `${axios.defaults.baseURL}/image/avatar/${userId}`;
  }

  // TODO: could return the error message in case of error
  async loaderPopupAxiosErrorWrapper(
    loaderMessage: string,
    callback: () => Promise<void>
  ): Promise<void> {
    this.showLoader(loaderMessage);
    try {
      return await callback();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err.response);
      let errorMessage = "";
      if (err.response && err.response.data) {
        errorMessage = err.response.data.error;
      } else {
        errorMessage = err.message;
      }
      this.showPopup(errorMessage || "Unexpected error", true);
    } finally {
      this.hideLoader();
    }
  }
}
</script>
