<template>
  <div v-if="show" class="loader-overlay">
    <div class="loader">
      <div class="loader-text">
        <p v-for="line in messageLines()" :key="line">
          {{ line }}
        </p>
      </div>
      <svg class="spinner" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
})
export default class GlobalLoader extends Vue {
  show!: boolean;
  message!: string;

  closePopup() {
    this.$emit("close");
  }

  messageLines() {
    return this.message.split("\n");
  }
}
</script>
