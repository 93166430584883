<template>
  <div v-if="show" class="user-popup-menu">
    <div class="dropdown-overlay" @click.self="closePopup">
      <div class="dropdown">
        <div class="row">
          <div class="col-md-6 account-type-text">
            {{ userInfo.accountType }}
          </div>
          <div class="col-md-6 id-text">ID: {{ userInfo.id }}</div>
        </div>
        <div class="row">
          <div class="col-md-12 email-text">
            {{ userInfo.email }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-7 logout" @click="logOut()">
            <img
              src="./../assets/icons/log_out.svg"
              alt="logout"
              class="icon"
            />
            <span>
              {{ $t("account.menu.logOut") }}
            </span>
          </div>
          <div class="col-md-5 languages">
            <img
              :class="{ 'active-lang': getLanguage() === 'cs' }"
              src="./../assets/flag_cs.png"
              alt="Czech"
              @click="changeLanguage('cs')"
            />
            <img
              :class="{ 'active-lang': getLanguage() === 'en' }"
              src="./../assets/flag_en.png"
              alt="English"
              @click="changeLanguage('en')"
            />
            <img
              :class="{ 'active-lang': getLanguage() === 'de' }"
              src="./../assets/flag_de.png"
              alt="Deutsch"
              @click="changeLanguage('de')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { logOut, logFrontendAction } from "@/injection-keys";

interface UserInfo {
  accountType: string;
  nickName: string;
  email: string;
  id: string;
  notifications: number;
  messages: number;
}

@Options({
  props: {
    show: {
      required: true,
    },
    userInfo: {
      type: Object as () => UserInfo,
      required: true,
    },
  },
  inject: {
    logOut: logOut,
    logFrontendAction: logFrontendAction,
  },
})
export default class UserPopupMenu extends Vue {
  show!: boolean;
  userInfo!: UserInfo;
  shouldShowUserDetails = true;
  logOut!: () => void;
  logFrontendAction!: (type: string, message: string) => void;

  closePopup() {
    this.$emit("close");
  }

  async changeLanguage(lang: string) {
    this.$i18n.locale = lang;
    await this.logFrontendAction(`change_language_user`, `Changed to: ${lang}`);
  }

  getLanguage(): string {
    return this.$i18n.locale;
  }
}
</script>
