<template>
  <div v-if="show" class="popup" @click.self="closePopup">
    <div
      :class="{
        'popup-content': true,
        centered: true,
        'red-border': isError,
        'green-border': !isError,
      }"
    >
      <span class="close">&times;</span>
      <p v-for="line in messageLines()" :key="line">
        {{ line }}
      </p>
      <button
        :class="{
          'ok-button': true,
          red: isError,
          green: !isError,
        }"
        class=""
        @click="closePopup"
      >
        Ok
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      required: true,
    },
  },
})
export default class GlobalPopup extends Vue {
  show!: boolean;
  message!: string;
  isError!: boolean;

  // TODO: make Escape key close the popup
  closePopup() {
    this.$emit("close");
  }

  messageLines() {
    return this.message.split("\n");
  }
}
</script>
