export const showPopup = Symbol("showPopup");
export const showLoader = Symbol("showLoader");
export const hideLoader = Symbol("hideLoader");
export const logOut = Symbol("logOut");
export const logFrontendAction = Symbol("logFrontendAction");
export const imageAssetUrl = Symbol("imageAssetUrl");
export const imageAvatarUrl = Symbol("imageAvatarUrl");
export const loaderPopupAxiosErrorWrapper = Symbol(
  "loaderPopupAxiosErrorWrapper"
);
