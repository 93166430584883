<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="bigger">Virtual Gallery</div>
          <div class="medium">
            {{ $t("footer.descriptionFirst") }}
          </div>
          <div class="medium">
            {{ $t("footer.descriptionSecond") }}
          </div>
        </div>
        <div class="col-md-2 offset-md-1">
          <div class="bigger">
            {{ $t("footer.join") }}
          </div>
        </div>
        <div class="col-md-2">
          <img src="./../assets/zelvuska_nft_R.png" alt="Logo" />
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-3">
          <p>© {{ getFullYear() }} Virtual gallery s.r.o</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {},
})
export default class Footer extends Vue {
  getFullYear() {
    return new Date().getFullYear();
  }
}
</script>
