import { createStore, type Commit } from "vuex";
import axios from "./axios";

export interface State {
  isAuthenticated: boolean;
  userEmail: string;
  userAccountType: string;
}

const state: State = {
  isAuthenticated: false,
  userEmail: "",
  userAccountType: "",
};

const mutations = {
  setAuthenticated(state: State, isAuthenticated: boolean) {
    state.isAuthenticated = isAuthenticated;
  },
  setEmail(state: State, email: string) {
    state.userEmail = email;
  },
  setAccountType(state: State, accountType: string) {
    state.userAccountType = accountType;
  },
};

const actions = {
  logUserIn(
    { commit }: { commit: Commit },
    payload: { email: string; accountType: string }
  ) {
    commit("setAuthenticated", true);
    commit("setEmail", payload.email);
    commit("setAccountType", payload.accountType);
  },
  logUserOut({ commit }: { commit: Commit }) {
    commit("setAuthenticated", false);
    commit("setEmail", "");
    commit("setAccountType", "");
  },
  async isAuthenticated(): Promise<boolean> {
    try {
      const response = await axios.post("/internal/auth", {});
      // User is authenticated when we receive back his email
      const userEmail = response.data?.data?.email;
      return Boolean(userEmail);
    } catch (err) {
      return false;
    }
  },
};

export const store = createStore<State>({
  state,
  mutations,
  actions,
});
