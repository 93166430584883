import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import { Store } from "vuex";
import { type State, store } from "./../store";

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/NotificationsView.vue"),
  },
  {
    path: "/create_notifications",
    name: "create_notifications",
    component: () => import("../views/CreateNotificationsView.vue"),
  },
  {
    path: "/404",
    name: "not_found",
    component: NotFoundView,
  },
  {
    path: "/:catchAll(.*)", // This is a "catch-all" route
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

async function authCheck(
  to: RouteLocationNormalized,
  store: Store<State>
): Promise<boolean> {
  if (to.path === "/login") {
    return true;
  }

  try {
    return await store.dispatch("isAuthenticated");
  } catch (error) {
    console.error("Auth check failed:", error);
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  // Check if the route requires authentication
  // Redirect to a login page if the user is not authenticated
  // Otherwise, allow the navigation
  if (!(await authCheck(to, store))) {
    next({ path: "/login" });
    return;
  }

  next();
});

export default router;
