<template>
  <div class="centered">
    <h1>{{ $t("notFound.header") }}</h1>
    <p>{{ $t("notFound.text") }}</p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class NotFoundView extends Vue {}
</script>
