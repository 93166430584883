export default {
  nav: {
    home: "Domů",
    about: "O nás",
    mint: "Ražba",
    login: "Přihlásit se",
    register: "Registrovat",
    account: "Účet",
    gallery: "Galerie",
    certifications: "Certifikace",
    market: "Tržiště",
    investor: "Investor",
    artist: "Umělec",
    depositary: "Depozitář",
    creator: "Výrobce",
    news: "Odběr novinek",
    contact: "Kontakt",
    gdpr: "Zásady ochrany osobních údajů",
    tos: "Podmínky služby",
    search: "Dílo, umělec...",
    createArt: "Vytvořit dílo",
  },
  about: {
    title:
      "Virtual Gallery je unikátní projekt zaměřený na propojení skutečných hodnot s digitálními aktivy.",
    mission: "Naše mise je...",
    team: "Náš tým:",
    partners: "Parteři:",
  },
  account: {
    menu: {
      summary: "Souhrn",
      virtualGallery: "Virtuální Galerie",
      wallet: "Peněženka",
      recommendations: "Doporučení",
      profile: "Můj profil",
      settings: "Nastavení",
      security: "Zabezpečení",
      logOut: "Odhlásit se",
      successfulLogOut: "Odhlášení proběhlo úspěšně.",
      notLoggedIn: "Nejste přihlášen.",
    },
    summary: {
      userId: "ID uživatele",
      accountType: "Typ účtu",
      lastLogin: "Poslední přihlášení",
      portfolioBuyingPrice: "Nákupní cena portfolia",
      sold: "Prodáno",
      averageProfit: "Průměrný zisk",
      assetsInDeposit: "Aktiva v depozitu",
      assetsInSelfCustody: "Aktiva v vlastní úschově",
      assetsInGallery: "Aktiva v galerii",
      profitFromRoyalties: "Zisk z licenčních poplatků",
      notFullyRegistered:
        "Nejste plně registrován. Prosím dokončete registraci. Klikněte zde pro pokračování.",
    },
    virtualGallery: {
      galleryURL: "URL vaší galerie",
      followers: "Sledující",
      following: "Sledované",
      yourGallery: "Vaše galerie",
    },
    wallet: {
      btc: "BTC",
      fiat: "Hotovost",
      transactionAmount: "Částka transakce",
      depositWithdraw: "Vklad / Výběr",
      yourPortfolio: "Vaše portfolio",
      wallet: "Peněženka",
      marketplace: "Tržiště",
      transactionHistory: "Historie transakcí",
      btcCertificates: "BTC certifikáty",
    },
    recommendations: {
      recommendationsURL: "URL vašeho doporučení",
      provisionSummary: "Souhrn provizí",
      notPaid: "Nezaplaceno",
      paymentRequests: "Žádosti o platbu",
      alreadyPaid: "Již zaplaceno",
      getPayment: "Získat platbu",
      client: "Klient",
      level: "Úroveň",
      date: "Datum",
      lastSale: "Poslední prodej",
      portfolioSize: "Velikost portfolia",
      provisionsToPay: "Provize k zaplacení",
      provisionSizes: "Velikosti provizí",
      codeChanged: "Kód byl úspěšně změněn.",
      codeEmpty: "Kód nemůže být prázdný.",
    },
    profile: {
      userId: "ID uživatele",
      accountType: "Typ účtu",
      lastLogin: "Poslední přihlášení",
      name: "Jméno",
      surname: "Příjmení",
      nickName: "Přezdívka",
      web: "Web",
      phone: "Mobil",
      email: "email",
      instagram: "Instagram",
      twitter: "Twitter",
      youtube: "YouTube",
      telegram: "Telegram",
      discord: "Discord",
      tiktok: "TikTok",
      changeAvatar: "Změnit avatar",
      removeAvatar: "Odstranit avatar",
      dataUpdated: "Data byla úspěšně aktualizována.",
      description: "Popis",
      fullRegistration: "Plná registrace",
      subjectType: "Typ subjektu",
      type: {
        individual: "Fyzická osoba",
        entrepreneur: "Podnikatel",
        company: "Společnost",
      },
      bankNumber: "Bankovní účet",
      homeAddress: "Doručovací adresa",
      contractDetails: "Fakturační údaje",
      streetAndNumber: "Ulice a číslo popisné",
      city: "Město",
      postalNumber: "PSČ",
      ico: "IČO",
      dic: "DIČ",
      companyName: "Název firmy",
      detailsUpdated: "Detaily byly úspěšně aktualizovány.",
      avatarChangedSuccessfully: "Avatar byl úspěšně změněn.",
    },
    security: {
      accountSecurity: "Zabezpečení účtu",
      twoFA: "2FA",
      verifiedIdentity: "Ověřená identita",
      antiPhishingCode: "Anti-phishingový kód",
      verifiedBankAccount: "Ověřený bankovní účet",
      titleTwoFA: "Dvoufázové ověření (2FA)",
      biometrics: "Biometrie",
      googleAuthenticator: "Google Authenticator",
      phoneVerification: "Ověření telefonního čísla",
      emailVerification: "Ověření emailové adresy",
      advancedSecurity: "Pokročilé zabezpečení",
      password: "Heslo",
      accountConnection: "Připojení účtu",
      fundWithdrawal: "Výběr prostředků",
    },
  },
  buttons: {
    show: "Zobrazit",
    change: "Změnit",
    manage: "Spravovat",
    edit: "Upravit",
    save: "Uložit",
    login: "Přihlásit se",
    register: "Registrovat se",
    logOut: "Odhlásit se",
    qrcode: "QR kód",
    send: "Odeslat",
    statistics: "Statistiky",
    termsAndConditions: "Podmínky",
    cancel: "Zrušit",
  },
  sortBy: {
    sortBy: "Seřadit podle",
    price: "Cena",
    date: "Datum",
    type: "Typ",
    artist: "Umělec",
  },
  assetType: {
    wines: "Vína",
    art: "Umění",
    veterans: "Veteráni",
  },
  home: {
    header: "Virtuální Galerie - kde se snoubí umění a technologie",
    welcome: "Vítejte v budoucnosti umění.",
    exploreBtn: "PROZKOUMEJ",
    desc0: "Startujeme novou éru ",
    desc1: "digitálních & reálných hodnot.",
    desc2:
      "Autenticita každého díla je zaznamenána na decentralizovaném Bitcoinovém blockchainu.",
    desc3: "Vaše cennosti jsou chráněny v dokonale zabezpečeném depozitáři.",
    desc4: "Vytvořte si svou virtuální galerii právě teď!",
  },
  login: {
    header: "Přihlásit se do aplikace",
    mailOrPhone: "Email",
    password: "Heslo",
    createNewAccount: "Vytvořit nový účet",
    loginSuccessMessage: "Přihlášení proběhlo úspěšně.",
    alreadyLoggedIn: "Již jste přihlášen.",
    forgotPassword: "Zapomenuté heslo?",
    loginViaGoogle: "Login přes Google",
    loginViaApple: "Login přes Apple",
  },
  register: {
    header: "Registrovat se do aplikace",
    finishHeader: "Dokončit registraci",
    name: "Jméno",
    surname: "Příjmení",
    email: "Email",
    phone: "Telefon",
    accountType: "Typ účtu",
    emailCode: "Zadejte ověřovací kód z emailu",
    phoneCode: "Zadejte ověřovací kód z telefonu",
    password: "Zadejte své heslo",
    passwordConfirmation: "Potvrďte své heslo",
    alreadyHaveAccount: "Už máte účet?",
    registerFirstSuccess:
      "Registrace probíhá. Prosím zkontrolujte email a telefon pro verifikační kódy.",
    registerSecondSuccess: "Registrace proběhla úspěšně. Prosím přihlašte se.",
    loader: "Probíhá registrace...",
  },
  registerFinish: {
    header: "Dokončit registraci",
    success: "Registrace proběhla úspěšně.",
  },
  mint: {
    header: "Ražba ordinálu",
    textContent: "Textový obsah",
    btcAddress: "BTC taproot adresa (testnet)",
  },
  accountType: {
    investor: "Investor",
    artist: "Umělec",
    depository: "Skladiště",
    creator: "Tvůrce",
  },
  words: {
    item: "položka | položky",
  },
  footer: {
    descriptionFirst:
      "První a největěí digitální tržiště pro bitcoinové certifikáty kryté reálnými hodnotami.",
    descriptionSecond:
      "Nakupujte, prodávejte, nabízejte licence a objevujte expluzivní digitalizované investiční objekty.",
    join: "Připojte se ke komunitě",
  },
  notFound: {
    header: "404 - Stránka nenalezena",
    text: "Stránka neexistuje.",
  },
  asset: {
    type: "Typ",
    owner: "Majitel",
    depositary: "Depozitář",
    artist: "Umělec",
    created: "Vytvořeno",
  },
  passwordChange: {
    title: "Zadejte heslo a změňte na nové",
    old: "Zadejte staré heslo",
    new: "Zadejte nové heslo",
    confirm: "Zadejte nové heslo znovu",
    changePassword: "Změnit heslo",
    changedSuccessfully: "Heslo bylo úspěšně změněno.",
  },
  forgottenPassword: {
    title: "Zadejte email vašeho účtu",
    send: "Odeslat email",
    email: "Email",
  },
  passwordReset: {
    title: "Obnovte své heslo",
    password: "Vaše nové heslo",
    passwordConfirmation: "Potvrďte heslo",
    button: "Změnit heslo",
    success: "Vaše heslo bylo úspěšně změněno.",
    notMatch: "Hesla se neshodují.",
    loader: "Heslo se resetuje...",
  },
  loader: {
    gettingData: "Načítání dat...",
    loading: "Načítání...",
    verifying: "Ověřování...",
    savingData: "Ukládání dat...",
    sendingEmail: "Odesílání emailu...",
    loggingOut: "Odhlášení...",
    uploading: "Nahrávání...",
    searching: "Hledání...",
  },
  createAsset: {
    assetCreation: "Vytvoření nového díla",
    createAsset: "Vytvořit dílo",
    certificateNumber: "Číslo certifikátu (volitelné)",
    assetName: "Název díla",
    creationYear: "Rok vytvoření",
    description: "Popis díla (600 znaků)",
    creationYearNotValid: "Neplatný rok vytvoření",
    height: "Výška (cm)",
    width: "Šířka (cm)",
    technique: "Technika",
    style: "Styl",
    success: "Dílo bylo úspěšně vytvořeno",
    uploadPicture: "Nahrát obrázek",
    pleaseSelectFile: "Prosím nahrajte obrázek",
    noPermissionToCreateArt: "Nemáte práva pro vytváření děl.",
    techniqueOptions: {
      one: "Akryl",
      two: "Balakryl",
      three: "Calakryl",
    },
    styleOptions: {
      one: "MůjStyl",
      two: "PyStyl",
      three: "VášStyl",
    },
  },
  search: {
    title: "Výsledky hledání",
    artistsTitle: "Umělci",
    assetsTitle: "Díla",
    noResults: "Žádné výsledky",
  },
  passwordsDoNotMatch: "Hesla se neshodují.",
  pleaseTryAgain: "Prosím zkuste to znovu.",
  pleaseEnterAValidValue: "Prosím zadejte platnou hodnotu.",
  turnOn: "Zapnout",
  turnOff: "Vypnout",
};
